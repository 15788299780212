import router from '@/router'
import Vue from 'vue'

import BookingService from '@/services/BookingService.js'
import CustomerCreditService from '@/services/CustomerCreditService.js'
import TallyService from '@/services/TallyService.js'

export const namespaced = true

export const state = {
  draft: null,
  dialog: {
    // OBS: finns ett liknande objekt i customer.js (customer.dialog.bookingOverlay), ändringar i något objekt borde kanske reflekteras i det andra
    show: false,
    isLoading: false,
    fetchingChunks: [],
    bookingId: null, // Sätts innan booking hämtats, för att säkerställa att rätt request skriver över data i dialogen
    openTab: {
      tabIndex: 'booking-information',
      subTabIndex: ''
    },
    booking: null,
    charges: [],
    invoices: [],
    receipts: [],
    emails: [],
    accommodationChargesCalendarItems: [],
    editingRecords: []
  },
  formDialog: {
    show: false
  },
  checkinDialog: {
    show: false,
    booking: null,
    errorMessage: '',
    accommodationChargesCalendarItems: []
  },
  lateCheckoutDialog: {
    show: false,
    booking: null
  },
  register: {
    // Saker relaterat till att ha en öppen booking i kassan, men inte är direkt relaterat till kassan/varukorgen i sig, det ligger i cart.js
    booking: null, // OBS: öppen booking också finns i cart.js
    bookingId: null, // sätts innan booking har hämtats, därför som både booking och bookingId behövs
    isParkingBookingIds: [],
    calendarItems: [],
    isFetchingCalendarItems: false,
    isFetchingBooking: false,
    invoices: [],
    isFetchingInvoices: false,
    receipts: [],
    isFetchingReceipts: false,
    accommodationSums: null,
    isFetchingAccommodationSums: false,
    timestampLastAccommodationSums: 0,
    isFetchingTallyCharges: false,
    tallyErrorMessage: '',
    isFetchingCustomerCredit: false,
    customerCredit: 0
  },
  isCheckingInBookingIds: [],
  isReversingCheckinBookingIds: [],
  isReversingCheckoutBookingIds: [],
  isCheckingOutBookingIds: [],
  isCancellingBookingIds: [],
  isReversingCancelBookingIds: [],
  bookings: [],
  isFetchingBookings: false,
  isSendingConfirmationBookingIds: [],
  isAwaitingEmailSavedBookingIds: [],
  isSavingAccommodationCharges: false,
  timestampLastCalendarItems: 0,
  bookingsListCheckinsShowTomorrow: false,
  bookingsListCheckoutsShowTomorrow: false,

  triggerAccommodationChargesUpdated: 0, // Denna kan bevakas för att hålla koll på när det sker en ändring i platsavgifter (just nu sker bara ändringar från platsavgiftskalendern)
  triggerChargesUpdated: 0,
  triggerBookingUpdated: 0 // Inkrementeras vid alla booking ändringar (från den här enheten)
}

export const mutations = {
  SET_DRAFT (state, val) {
    state.draft = val
  },
  SET_SHOW_FORM_DIALOG (state, val) {
    state.formDialog.show = val
  },
  SET_SHOW_DIALOG (state, val) {
    state.dialog.show = val
  },
  SET_DIALOG_IS_LOADING (state, val) {
    state.dialog.isLoading = val
  },
  SET_DIALOG_FETCHING_CHUNKS (state, payload) {
    if (payload.isFetching) {
      const i = state.dialog.fetchingChunks.findIndex(id => id === payload.chunkName)
      if (i < 0) {
        state.dialog.fetchingChunks.push(payload.chunkName)
      }
    } else {
      const i = state.dialog.fetchingChunks.findIndex(id => id === payload.chunkName)
      state.dialog.fetchingChunks.splice(i, 1)
    }
  },
  SET_DIALOG_BOOKING (state, val) {
    state.dialog.booking = val
  },
  SET_DIALOG_BOOKING_ID (state, val) {
    state.dialog.bookingId = val
  },
  SET_DIALOG_BOOKING_REMARKS (state, val) {
    if (state.dialog.booking) {
      // dialog kan ha stängts, men remarks har redan sparats i db så inga problem
      state.dialog.booking.remarks = val
    }
  },
  SET_DIALOG_BOOKING_CHARGES (state, val) {
    state.dialog.charges = val
  },
  SET_DIALOG_BOOKING_INVOICES (state, val) {
    state.dialog.invoices = val
  },
  SET_DIALOG_BOOKING_RECEIPTS (state, val) {
    state.dialog.receipts = val
  },
  SET_DIALOG_BOOKING_EMAILS (state, val) {
    state.dialog.emails = val
  },
  SET_DIALOG_BOOKING_EDITING_RECORDS (state, val) {
    state.dialog.editingRecords = val
  },
  SET_DIALOG_BOOKING_CALENDAR_ITEMS (state, val) {
    state.dialog.accommodationChargesCalendarItems = val
  },
  ADD_DIALOG_BOOKING_EMAIL (state, val) {
    const index = state.dialog.emails.findIndex(email => {
      return email.id === val.id
    })
    if (index >= 0) {
      state.dialog.emails.splice(index, 1, val)
    } else {
      state.dialog.emails.push(val)
    }
  },
  SET_DIALOG_OPEN_TAB (state, { tabIndex, subTabIndex }) {
    if (tabIndex) {
      state.dialog.openTab.tabIndex = tabIndex
    }
    if (subTabIndex) {
      state.dialog.openTab.subTabIndex = subTabIndex
    }
  },
  SET_BOOKINGS (state, val) {
    state.bookings = val
  },
  ADD_BOOKING (state, val) {
    const index = state.bookings.findIndex(booking => {
      return booking.id === val.id
    })
    if (index >= 0) {
      state.bookings.splice(index, 1, val)
    } else {
      state.bookings.push(val)
    }
  },
  REMOVE_BOOKING (state, val) {
    state.bookings = state.bookings.filter(booking => {
      return booking.id !== val
    })
  },
  SET_IS_FECTHING_BOOKINGS (state, val) {
    state.isFetchingBookings = val
  },
  SET_SHOW_CHECKIN_DIALOG (state, val) {
    state.checkinDialog.show = val
  },
  SET_CHECKIN_DIALOG_BOOKING (state, val) {
    state.checkinDialog.booking = val
  },
  SET_CHECKIN_DIALOG_ERROR_MESSAGE (state, val) {
    state.checkinDialog.errorMessage = val
  },
  SET_CHECKIN_DIALOG_ACCOMMODATION_CHARGES_CALENDAR_ITEMS (state, val) {
    state.checkinDialog.accommodationChargesCalendarItems = val
  },
  SET_SHOW_LATE_CHECKOUT_DIALOG (state, val) {
    state.lateCheckoutDialog.show = val
  },
  SET_LATE_CHECKOUT_DIALOG_BOOKING (state, val) {
    state.lateCheckoutDialog.booking = val
  },
  SET_REGISTER_BOOKING (state, val) {
    state.register.booking = val
  },
  SET_REGISTER_BOOKING_ID (state, val) {
    state.register.bookingId = val
  },
  SET_REGISTER_CALENDAR_ITEMS (state, val) {
    state.register.calendarItems = val
  },
  SET_REGISTER_IS_FETCHING_CALENDAR_ITEMS (state, val) {
    state.register.isFetchingCalendarItems = val
  },
  SET_REGISTER_INVOICES (state, val) {
    state.register.invoices = val
  },
  SET_REGISTER_IS_FETCHING_INVOICES (state, val) {
    state.register.isFetchingInvoices = val
  },
  SET_REGISTER_RECEIPTS (state, val) {
    state.register.receipts = val
  },
  SET_REGISTER_IS_FETCHING_RECEIPTS (state, val) {
    state.register.isFetchingReceipts = val
  },
  SET_REGISTER_ACCOMMODATION_SUMS (state, val) {
    state.register.accommodationSums = val
  },
  SET_REGISTER_IS_FETCHING_ACCOMMODATION_SUMS (state, val) {
    state.register.isFetchingAccommodationSums = val
  },
  SET_REGISTER_TIMESTAMP_LAST_ACCOMMODATION_SUMS (state, val) {
    state.register.timestampLastAccommodationSums = val
  },
  SET_IS_FETCHING_REGISTER_BOOKING (state, val) {
    state.register.isFetchingBooking = val
  },
  SET_IS_FETCHING_REGISTER_TALLY_CHARGES (state, val) {
    state.register.isFetchingTallyCharges = val
  },
  SET_REGISTER_TALLY_ERROR_MESSAGE (state, val) {
    state.register.tallyErrorMessage = val
  },
  SET_IS_FETCHING_REGISTER_CUSTOMER_CREDIT (state, val) {
    state.register.isFetchingCustomerCredit = val
  },
  SET_REGISTER_CUSTOMER_CREDIT (state, val) {
    state.register.customerCredit = val
  },
  SET_IS_CHECKING_IN_BOOKING_IDS (state, payload) {
    if (payload.val) {
      const i = state.isCheckingInBookingIds.findIndex(id => id === payload.bookingId)
      if (i < 0) {
        state.isCheckingInBookingIds.push(payload.bookingId)
      }
    } else {
      const i = state.isCheckingInBookingIds.findIndex(id => id === payload.bookingId)
      state.isCheckingInBookingIds.splice(i, 1)
    }
  },
  SET_IS_REVERSING_CHECKIN_BOOKING_IDS (state, payload) {
    if (payload.val) {
      const i = state.isReversingCheckinBookingIds.findIndex(id => id === payload.bookingId)
      if (i < 0) {
        state.isReversingCheckinBookingIds.push(payload.bookingId)
      }
    } else {
      const i = state.isReversingCheckinBookingIds.findIndex(id => id === payload.bookingId)
      state.isReversingCheckinBookingIds.splice(i, 1)
    }
  },
  SET_IS_CHECKING_OUT_BOOKING_IDS (state, payload) {
    if (payload.val) {
      const i = state.isCheckingOutBookingIds.findIndex(id => id === payload.bookingId)
      if (i < 0) {
        state.isCheckingOutBookingIds.push(payload.bookingId)
      }
    } else {
      const i = state.isCheckingOutBookingIds.findIndex(id => id === payload.bookingId)
      state.isCheckingOutBookingIds.splice(i, 1)
    }
  },
  SET_IS_CANCELLING_BOOKING_IDS (state, payload) {
    if (payload.val) {
      const i = state.isCancellingBookingIds.findIndex(id => id === payload.bookingId)
      if (i < 0) {
        state.isCancellingBookingIds.push(payload.bookingId)
      }
    } else {
      const i = state.isCancellingBookingIds.findIndex(id => id === payload.bookingId)
      state.isCancellingBookingIds.splice(i, 1)
    }
  },
  SET_IS_REVERSING_CANCEL_BOOKING_IDS (state, payload) {
    if (payload.val) {
      const i = state.isReversingCancelBookingIds.findIndex(id => id === payload.bookingId)
      if (i < 0) {
        state.isReversingCancelBookingIds.push(payload.bookingId)
      }
    } else {
      const i = state.isReversingCancelBookingIds.findIndex(id => id === payload.bookingId)
      state.isReversingCancelBookingIds.splice(i, 1)
    }
  },
  SET_IS_REVERSING_CHECKOUT_BOOKING_IDS (state, payload) {
    if (payload.val) {
      const i = state.isReversingCheckoutBookingIds.findIndex(id => id === payload.bookingId)
      if (i < 0) {
        state.isReversingCheckoutBookingIds.push(payload.bookingId)
      }
    } else {
      const i = state.isReversingCheckoutBookingIds.findIndex(id => id === payload.bookingId)
      state.isReversingCheckoutBookingIds.splice(i, 1)
    }
  },
  SET_IS_PARKING_BOOKING_IDS (state, payload) {
    if (payload.val) {
      const i = state.register.isParkingBookingIds.findIndex(id => id === payload.bookingId)
      if (i < 0) {
        state.register.isParkingBookingIds.push(payload.bookingId)
      }
    } else {
      const i = state.register.isParkingBookingIds.findIndex(id => id === payload.bookingId)
      state.register.isParkingBookingIds.splice(i, 1)
    }
  },
  SET_IS_SENDING_CONFIRMATION_BOOKING_IDS (state, payload) {
    if (payload.val) {
      const i = state.isSendingConfirmationBookingIds.findIndex(id => id === payload.bookingId)
      if (i < 0) {
        state.isSendingConfirmationBookingIds.push(payload.bookingId)
      }
    } else {
      const i = state.isSendingConfirmationBookingIds.findIndex(id => id === payload.bookingId)
      state.isSendingConfirmationBookingIds.splice(i, 1)
    }
  },
  SET_IS_AWAITING_EMAIL_SAVED_BOOKING_ID (state, payload) {
    if (payload.val) {
      const i = state.isAwaitingEmailSavedBookingIds.findIndex(id => id === payload.bookingId)
      if (i < 0) {
        state.isAwaitingEmailSavedBookingIds.push(payload.bookingId)
      }
    } else {
      const i = state.isAwaitingEmailSavedBookingIds.findIndex(id => id === payload.bookingId)
      state.isAwaitingEmailSavedBookingIds.splice(i, 1)
    }
  },
  SET_IS_SAVING_ACCOMMODATION_CHARGES (state, val) {
    state.isSavingAccommodationCharges = val
  },
  SET_TIMESTAMP_LAST_CALENDAR_ITEMS (state, val) {
    state.timestampLastCalendarItems = val
  },
  SET_BOOKINGS_LIST_CHECKINS_SHOW_TOMORROW (state, val) {
    state.bookingsListCheckinsShowTomorrow = val
  },
  SET_BOOKINGS_LIST_CHECKOUTS_SHOW_TOMORROW (state, val) {
    state.bookingsListCheckoutsShowTomorrow = val
  },
  TRIGGER_ACCOMMODATION_CHARGES_UPDATED (state) {
    state.triggerAccommodationChargesUpdated++
  },
  TRIGGER_CHARGES_UPDATED (state) {
    state.triggerChargesUpdated++
  },
  TRIGGER_BOOKING_UPDATED (state) {
    state.triggerBookingUpdated++
  }
}

export const actions = {
  openDialogById ({ state, commit, dispatch }, { bookingId, startTab, refresh = false }) {
    if (startTab) {
      if (['invoices', 'receipts'].includes(startTab)) {
        commit('SET_DIALOG_OPEN_TAB', { tabIndex: 'invoices-receipts', subTabIndex: startTab })
      } else if (['accommodation-charges', 'other-charges'].includes(startTab)) {
        commit('SET_DIALOG_OPEN_TAB', { tabIndex: 'charges', subTabIndex: startTab })
      } else {
        commit('SET_DIALOG_OPEN_TAB', { tabIndex: startTab })
      }
    } else {
      commit('SET_DIALOG_OPEN_TAB', { tabIndex: 'booking-information' })
    }

    if (state.dialog.show && state.dialog.bookingId === bookingId && !refresh) {
      // OBS: Hämtar inte om booking om redan öppen med samma booking om inte refresh = true
      return false
    }

    commit('SET_DIALOG_BOOKING_ID', bookingId) // dialog.bookingId garanterar att vi inte skriver över data i booking dialog med data tillhörande en annan booking
    dispatch('resetBookingDialog')
    commit('SET_DIALOG_IS_LOADING', true)
    commit('SET_SHOW_DIALOG', true)

    return dispatch('getBookingDialogContent', bookingId)
      .then(() => {
        if (bookingId === state.dialog.bookingId) {
          commit('SET_DIALOG_IS_LOADING', false)
        }
      })
  },
  resetBookingDialog ({ commit }) {
    commit('SET_DIALOG_BOOKING', null)
    commit('SET_DIALOG_BOOKING_CHARGES', [])
    commit('SET_DIALOG_BOOKING_INVOICES', [])
    commit('SET_DIALOG_BOOKING_RECEIPTS', [])
    commit('SET_DIALOG_BOOKING_EMAILS', [])
    commit('SET_DIALOG_BOOKING_EDITING_RECORDS', [])
    commit('SET_DIALOG_BOOKING_CALENDAR_ITEMS', [])
  },
  getBookingDialogContent ({ dispatch }, bookingId) {
    return Promise.allSettled([
      dispatch('getBooking', { bookingId }),
      dispatch('getBookingCharges', bookingId),
      dispatch('getBookingInvoices', bookingId),
      dispatch('getBookingReceipts', bookingId),
      dispatch('getBookingEmails', bookingId),
      dispatch('getBookingCalendarItems', bookingId),
      dispatch('getBookingEditingRecords', bookingId)
    ])
  },
  getBooking ({ rootState, state, commit }, { bookingId }) {
    if (bookingId === state.dialog.bookingId) {
      commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'booking', isFetching: true })
    }
    if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
      commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'booking', isFetching: true }, { root: true })
    }
    return BookingService.getBooking({ bookingId })
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichBooking(data.data.booking)
          if (bookingId === state.dialog.bookingId) {
            commit('SET_DIALOG_BOOKING', data.data.booking)
          }
          if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
            commit('customer/SET_BOOKING_OVERLAY_BOOKING', data.data.booking, { root: true })
          }
        }
      })
      .finally(() => {
        if (bookingId === state.dialog.bookingId || !state.dialog.show) {
          commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'booking', isFetching: false })
        }
        if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId || !rootState.customer.dialog.bookingOverlay.show) {
          commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'booking', isFetching: false }, { root: true })
        }
      })
  },
  getBookingCharges ({ rootState, state, commit }, bookingId) {
    if (bookingId === state.dialog.bookingId) {
      commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'charges', isFetching: true })
    }
    if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
      commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'charges', isFetching: true }, { root: true })
    }
    return BookingService.getBookingCharges(bookingId)
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichCharge(data.data.charges)
          if (bookingId === state.dialog.bookingId) {
            commit('SET_DIALOG_BOOKING_CHARGES', data.data.charges)
          }
          if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
            commit('customer/SET_BOOKING_OVERLAY_BOOKING_CHARGES', data.data.charges, { root: true })
          }
        }
      })
      .finally(() => {
        if (bookingId === state.dialog.bookingId || !state.dialog.show) {
          commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'charges', isFetching: false })
        }
        if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId || !rootState.customer.dialog.bookingOverlay.show) {
          commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'charges', isFetching: false }, { root: true })
        }
      })
  },
  getBookingInvoices ({ rootState, state, commit }, bookingId) {
    if (bookingId === state.dialog.bookingId) {
      commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'invoices', isFetching: true })
    }
    if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
      commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'invoices', isFetching: true }, { root: true })
    }
    return BookingService.getBookingInvoices(bookingId)
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichInvoice(data.data.invoices)
          if (bookingId === state.dialog.bookingId) {
            commit('SET_DIALOG_BOOKING_INVOICES', data.data.invoices)
          }
          if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
            commit('customer/SET_BOOKING_OVERLAY_BOOKING_INVOICES', data.data.invoices, { root: true })
          }
        }
      })
      .finally(() => {
        if (bookingId === state.dialog.bookingId || !state.dialog.show) {
          commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'invoices', isFetching: false })
        }
        if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId || !rootState.customer.dialog.bookingOverlay.show) {
          commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'invoices', isFetching: false }, { root: true })
        }
      })
  },
  getBookingReceipts ({ rootState, state, commit }, bookingId) {
    if (bookingId === state.dialog.bookingId) {
      commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'receipts', isFetching: true })
    }
    if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
      commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'receipts', isFetching: true }, { root: true })
    }
    return BookingService.getBookingReceipts(bookingId)
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichReceipt(data.data.receipts)
          if (bookingId === state.dialog.bookingId) {
            commit('SET_DIALOG_BOOKING_RECEIPTS', data.data.receipts)
          }
          if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
            commit('customer/SET_BOOKING_OVERLAY_BOOKING_RECEIPTS', data.data.receipts, { root: true })
          }
        }
      })
      .finally(() => {
        if (bookingId === state.dialog.bookingId || !state.dialog.show) {
          commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'receipts', isFetching: false })
        }
        if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId || !rootState.customer.dialog.bookingOverlay.show) {
          commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'receipts', isFetching: false }, { root: true })
        }
      })
  },
  getBookingEmails ({ rootState, state, commit }, bookingId) {
    if (bookingId === state.dialog.bookingId) {
      commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'emails', isFetching: true })
    }
    if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
      commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'emails', isFetching: true }, { root: true })
    }
    return BookingService.getBookingEmails(bookingId)
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichEmail(data.data.emails)
          if (bookingId === state.dialog.bookingId) {
            commit('SET_DIALOG_BOOKING_EMAILS', data.data.emails)
          }
          if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
            commit('customer/SET_BOOKING_OVERLAY_BOOKING_EMAILS', data.data.emails, { root: true })
          }
        }
      })
      .finally(() => {
        if (bookingId === state.dialog.bookingId || !state.dialog.show) {
          commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'emails', isFetching: false })
        }
        if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId || !rootState.customer.dialog.bookingOverlay.show) {
          commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'emails', isFetching: false }, { root: true })
        }
      })
  },
  getBookingEditingRecords ({ rootState, state, commit }, bookingId) {
    if (bookingId === state.dialog.bookingId) {
      commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'editing-records', isFetching: true })
    }
    if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
      commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'editing-records', isFetching: true }, { root: true })
    }
    return BookingService.getEditingRecords(bookingId)
      .then(({ data }) => {
        if (data.status === 'success') {
          // window.enrich.enrichEmail(data.data.emails)
          if (bookingId === state.dialog.bookingId) {
            commit('SET_DIALOG_BOOKING_EDITING_RECORDS', data.data.editing_records)
          }
          if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
            commit('customer/SET_BOOKING_OVERLAY_BOOKING_EDITING_RECORDS', data.data.editing_records, { root: true })
          }
        }
      })
      .finally(() => {
        if (bookingId === state.dialog.bookingId || !state.dialog.show) {
          commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'editing-records', isFetching: false })
        }
        if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId || !rootState.customer.dialog.bookingOverlay.show) {
          commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'editing-records', isFetching: false }, { root: true })
        }
      })
  },
  getBookingCalendarItems ({ rootState, state, commit }, bookingId) {
    if (bookingId === state.dialog.bookingId) {
      commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'accommodation-charges-calendar-items', isFetching: true })
    }
    if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
      commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'accommodation-charges-calendar-items', isFetching: true }, { root: true })
    }
    return BookingService.getAccommodationChargesCalendarItems(bookingId)
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichCalendarItem(data.data.calendar_items)
          if (bookingId === state.dialog.bookingId) {
            commit('SET_DIALOG_BOOKING_CALENDAR_ITEMS', data.data.calendar_items)
          }
          if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId) {
            commit('customer/SET_BOOKING_OVERLAY_BOOKING_CALENDAR_ITEMS', data.data.calendar_items, { root: true })
          }
        }
      })
      .finally(() => {
        if (bookingId === state.dialog.bookingId || !state.dialog.show) {
          commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'accommodation-charges-calendar-items', isFetching: false })
        }
        if (bookingId === rootState.customer.dialog.bookingOverlay.bookingId || !rootState.customer.dialog.bookingOverlay.show) {
          commit('customer/SET_BOOKING_OVERLAY_FETCHING_CHUNKS', { chunkName: 'accommodation-charges-calendar-items', isFetching: false }, { root: true })
        }
      })
  },
  startNewBooking ({ commit, rootGetters }, attributes) {
    const defaultCheckin = window.dayjs().startOf('day')
    const defaultCheckout = window.dayjs().startOf('day').add(1, 'days')
    const draft = {
      id: 0,
      customer: null,
      position: { // Samma format som i PositionPicker.vue
        full_name: rootGetters['position/defaultBookingCategory'].name,
        id: 0,
        category: rootGetters['position/defaultBookingCategory'],
        avatar: {
          icon: 'mdi-state-machine',
          text: '',
          color: 'blue accent-1'
        }
      },
      category: rootGetters['position/defaultBookingCategory'],
      checkIn: defaultCheckin.clone(),
      checkOut: defaultCheckout.clone(),
      adults: 2,
      children: 0,
      dogs: 0,
      channel: 'drop-in',
      lodgingType: null,
      type: 'regular',
      season: null,
      expected_arrival_time: null,
      cancellationPolicy: null,
      charges: [],
      accommodation_price: null
    }

    // Kan skicka med default attribut till formuläret med hjälpa via parametern i denna metoden
    if (attributes) {
      if (attributes.checkIn) {
        draft.checkIn = attributes.checkIn.clone()
      }
      if (attributes.checkOut) {
        draft.checkOut = attributes.checkOut.clone()
      }
      if (attributes.position) {
        draft.position = attributes.position
        draft.category = attributes.position.category
        draft.lodgingType = attributes.position.category.lodgingTypes[0]
      } else if (attributes.category) {
        draft.category = attributes.category
        draft.lodgingType = attributes.category.lodgingTypes[0]
      }

      if (attributes.customer) {
        draft.customer = attributes.customer
      }

      if (attributes.type) {
        draft.type = attributes.type
      }
    }

    commit('SET_DRAFT', draft)
    commit('SET_SHOW_FORM_DIALOG', true)
  },
  startEditBooking ({ commit }, originalBooking) {
    const booking = Object.assign({}, originalBooking)
    if (booking.checked_in_at) {
      Vue.set(booking, 'checkIn', booking.checked_in_at.clone().startOf('day'))
    } else {
      Vue.set(booking, 'checkIn', booking.check_in.clone())
    }

    if (booking.checked_out_at) {
      Vue.set(booking, 'checkOut', booking.checked_out_at.clone())
    } else {
      Vue.set(booking, 'checkOut', booking.check_out.clone())
    }

    Vue.set(booking, 'accommodation_price', booking.total_accommodation_amount)
    Vue.set(booking, 'charges', [])

    commit('SET_DRAFT', booking)
    commit('SET_SHOW_FORM_DIALOG', true)
  },
  checkIn ({ commit, dispatch }, bookingId) {
    commit('SET_CHECKIN_DIALOG_BOOKING', null)
    commit('SET_CHECKIN_DIALOG_ERROR_MESSAGE', '')
    commit('SET_SHOW_CHECKIN_DIALOG', true)
    commit('SET_IS_CHECKING_IN_BOOKING_IDS', { bookingId, val: true })

    BookingService.checkIn(bookingId)
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichBooking(data.data.booking)
          commit('SET_CHECKIN_DIALOG_BOOKING', data.data.booking)
          if (data.data.booking.access_driver === 'tally') {
            dispatch('tally/createProvision', data.data.booking, { root: true })
          }
        } else {
          // Booking i data attributet även vid error (förutom om booking med detta id inte finns)
          if (data.error_code !== 'booking_not_found') {
            window.enrich.enrichBooking(data.data.booking)
            commit('SET_CHECKIN_DIALOG_BOOKING', data.data.booking)
            commit('SET_CHECKIN_DIALOG_ERROR_MESSAGE', data.error_message)
          }
        }
      })
      .finally(() => {
        commit('SET_IS_CHECKING_IN_BOOKING_IDS', { bookingId, val: false })
        commit('TRIGGER_BOOKING_UPDATED')
      })
  },
  startCheckOut ({ commit }, { booking }) {
    const today = window.dayjs().startOf('day')
    if (booking.check_out.isBefore(today) && booking.type === 'regular') {
      // om sen utcheckning, och inte säsongsbokning, så visa late-checkout-dialog först istället för att gå till kassan direkt
      commit('SET_LATE_CHECKOUT_DIALOG_BOOKING', booking)
      commit('SET_SHOW_LATE_CHECKOUT_DIALOG', true)
    } else {
      router.push({ name: 'PosRegister', params: { chargable: 'booking', id: booking.id, checkout: 'checkout' } })
    }
  },
  checkOut ({ commit }, bookingId) {
    commit('SET_IS_CHECKING_OUT_BOOKING_IDS', { bookingId, val: true })

    BookingService.checkOut(bookingId)
      .then(({ data }) => {
        if (data.status === 'success') {
          router.push({ name: 'ActionsItems' })
        }
      })
      .finally(() => {
        commit('SET_IS_CHECKING_OUT_BOOKING_IDS', { bookingId, val: false })
        commit('TRIGGER_BOOKING_UPDATED')
      })
  },
  cancel ({ commit, dispatch }, bookingId) {
    commit('SET_IS_CANCELLING_BOOKING_IDS', { bookingId, val: true })

    BookingService.cancel(bookingId)
      .then(({ data }) => {
        if (data.status === 'success') {
          const booking = data.data.booking
          window.enrich.enrichBooking(booking)
          if (state.dialog.booking && (state.dialog.booking.id === bookingId)) {
            // Bokningen är fortfarande öppen i dialogen, ersätter dialog booking med uppdaterat objekt
            commit('SET_DIALOG_BOOKING', booking)
          }
          if (data.data.booking_cancellation) {
            const bookingCancellation = data.data.booking_cancellation
            dispatch('cancellationPolicy/openBookingCancellation', { bookingCancellation, showSuccessAlert: true }, { root: true })
          }
        }
      })
      .finally(() => {
        commit('SET_IS_CANCELLING_BOOKING_IDS', { bookingId, val: false })
        commit('TRIGGER_BOOKING_UPDATED')
      })
  },
  reverseCancel ({ commit }, bookingId) {
    commit('SET_IS_REVERSING_CANCEL_BOOKING_IDS', { bookingId, val: true })

    return BookingService.reverseCancel(bookingId)
      .then(({ data }) => {
        if (data.status === 'success') {
          const booking = data.data.booking
          window.enrich.enrichBooking(booking)
          if (state.dialog.booking && (state.dialog.booking.id === bookingId)) {
            // Bokningen är fortfarande öppen i dialogen, ersätter dialog booking med uppdaterat objekt
            commit('SET_DIALOG_BOOKING', booking)
          }
        }
      })
      .finally(() => {
        commit('SET_IS_REVERSING_CANCEL_BOOKING_IDS', { bookingId, val: false })
        commit('TRIGGER_BOOKING_UPDATED')
      })
  },
  reverseCheckin ({ state, commit }, bookingId) {
    commit('SET_IS_REVERSING_CHECKIN_BOOKING_IDS', { bookingId, val: true })

    BookingService.reverseCheckin(bookingId)
      .then(({ data }) => {
        if (data.status === 'success') {
          const booking = data.data.booking
          window.enrich.enrichBooking(booking)
          if (state.dialog.booking && (state.dialog.booking.id === bookingId)) {
            // Bokningen är fortfarande öppen i dialogen, ersätter dialog booking med uppdaterat objekt
            commit('SET_DIALOG_BOOKING', booking)
          }
          if (state.checkinDialog.booking && (state.checkinDialog.booking.id === bookingId)) {
            // Bokningen är fortfarande öppen i dialogen, ersätter dialog booking med uppdaterat objekt
            commit('SET_CHECKIN_DIALOG_BOOKING', booking)
          }
        }
      })
      .finally(() => {
        commit('SET_IS_REVERSING_CHECKIN_BOOKING_IDS', { bookingId, val: false })
        commit('TRIGGER_BOOKING_UPDATED')
      })
  },
  reverseCheckout ({ state, commit }, bookingId) {
    commit('SET_IS_REVERSING_CHECKOUT_BOOKING_IDS', { bookingId, val: true })

    BookingService.reverseCheckout(bookingId)
      .then(({ data }) => {
        if (data.status === 'success') {
          const booking = data.data.booking
          window.enrich.enrichBooking(booking)
          if (state.dialog.booking && (state.dialog.booking.id === bookingId)) {
            // Bokningen är fortfarande öppen i dialogen, ersätter dialog booking med uppdaterat objekt
            commit('SET_DIALOG_BOOKING', booking)
          }
          if (state.checkinDialog.booking && (state.checkinDialog.booking.id === bookingId)) {
            // Bokningen är fortfarande öppen i dialogen, ersätter dialog booking med uppdaterat objekt
            commit('SET_CHECKIN_DIALOG_BOOKING', booking)
          }
        }
      })
      .finally(() => {
        commit('SET_IS_REVERSING_CHECKOUT_BOOKING_IDS', { bookingId, val: false })
        commit('TRIGGER_BOOKING_UPDATED')
      })
  },
  fetchBookings ({ commit, dispatch }) {
    commit('SET_IS_FECTHING_BOOKINGS', true)
    return BookingService.getBookings()
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_BOOKINGS', data.data.bookings)
        }
      })
      .finally(() => {
        commit('SET_IS_FECTHING_BOOKINGS', false)
        window.channel.listen('BookingSaved', function (data) {
          dispatch('stats/fetchCategoryOccupation', { updateSilently: true }, { root: true }) // hämtar om beläggningsstatistiken då den sannolikt påverkats av denna bokningsändring
          const booking = data.booking
          window.enrich.enrichBooking(booking)
          commit('ADD_BOOKING', booking)
          if (booking.checked_out_at !== null) {
            commit('tally/REMOVE_BOOKING', booking.id, { root: true })
          }
        })
        window.channel.listen('BookingRemoved', function (data) {
          commit('REMOVE_BOOKING', data.booking_id)
        })
      })
  },
  enrichBookings ({ state }) {
    return new Promise((resolve, reject) => {
      window.enrich.enrichBooking(state.bookings)
      resolve(true)
    })
  },
  getRegisterData ({ commit, dispatch }, { bookingId }) {
    // metod för att hämta booking data till kassan, det är inte en metod för att gå till kassan och öppna booking
    // använder router för att gå till kassan och öppna booking istället: this.$router.push({ name: 'PosRegister', params: { chargable: 'booking', id: this.booking.id } })
    commit('SET_IS_LOADING_PAGE', true, { root: true })
    commit('SET_REGISTER_BOOKING_ID', bookingId)

    Promise.allSettled([
      dispatch('getRegisterBooking', bookingId),
      dispatch('getRegisterCharges', bookingId),
      dispatch('getRegisterChargesCalendarItems', bookingId),
      dispatch('getRegisterInvoices', bookingId),
      dispatch('getRegisterReceipts', bookingId),
      dispatch('getRegisterAccommodationSums', bookingId),
      dispatch('getRegisterCustomerCredit', bookingId)
    ])
      .then(() => {
        commit('SET_IS_LOADING_PAGE', false, { root: true })
      })

    // hämta tally charges ska inte vara en del av full page loadern
    // REVIEW: kanske hitta sätt att undvika denna request om inte använder tally?
    dispatch('getRegisterTallyCharges', bookingId)
  },
  getRegisterTallyCharges ({ commit }, bookingId) {
    commit('SET_IS_FETCHING_REGISTER_TALLY_CHARGES', true)
    commit('posCart/SET_TALLY_CHARGES', [], { root: true })
    commit('SET_REGISTER_TALLY_ERROR_MESSAGE', '')
    return TallyService.getTallyCharges({ bookingId })
      .then(({ data }) => {
        if (data.status === 'success' && state.register.bookingId === bookingId) {
          window.enrich.enrichCharge(data.data.tally_charges)
          commit('posCart/SET_TALLY_CHARGES', data.data.tally_charges, { root: true })
        } else if (data.status === 'error' && data.error_code === 'tally_error') {
          window.enrich.enrichCharge(data.data.tally_charges)
          commit('posCart/SET_TALLY_CHARGES', data.data.tally_charges, { root: true }) // tally_charges skickas trots tally_error - med tidigare hämtade charges
          commit('SET_REGISTER_TALLY_ERROR_MESSAGE', data.error_message)
        }
      })
      .finally(() => {
        commit('SET_IS_FETCHING_REGISTER_TALLY_CHARGES', false)
      })
  },
  getRegisterBooking ({ commit, dispatch }, bookingId) {
    commit('SET_IS_FETCHING_REGISTER_BOOKING', true)
    return BookingService.getBooking({ bookingId })
      .then(({ data }) => {
        if (data.status === 'success' && state.register.bookingId === bookingId) {
          window.enrich.enrichBooking(data.data.booking) // berikar även customer
          commit('SET_REGISTER_BOOKING', data.data.booking)
          dispatch('posCart/openBooking', { booking: data.data.booking }, { root: true })
        }
      })
      .finally(() => {
        commit('SET_IS_FETCHING_REGISTER_BOOKING', false)
      })
  },
  getRegisterAccommodationSums ({ state, commit }, bookingId) {
    commit('SET_REGISTER_ACCOMMODATION_SUMS', null)
    commit('SET_REGISTER_IS_FETCHING_ACCOMMODATION_SUMS', true)
    return BookingService.getAccommodationSums(bookingId)
      .then(({ data }) => {
        if (data.status === 'success') {
          if (state.register.bookingId === bookingId && data.data.timestamp > state.register.timestampLastAccommodationSums) {
            // Kontrollerar att bokningen i kassan inte tagits bort / byts ut sedan request skickades, och att vi inte skriver över en response som skickades senare
            commit('SET_REGISTER_TIMESTAMP_LAST_ACCOMMODATION_SUMS', data.data.timestamp)
            commit('SET_REGISTER_ACCOMMODATION_SUMS', data.data.accommodation_sums)
            commit('posCart/SET_ACCOMMODATION_CHARGES_AMOUNT', data.data.accommodation_sums.remaining_amount, { root: true })
          }
        }
      })
      .finally(() => {
        commit('SET_REGISTER_IS_FETCHING_ACCOMMODATION_SUMS', false)
      })
  },
  getRegisterCustomerCredit ({ state, commit }, bookingId) {
    commit('SET_REGISTER_CUSTOMER_CREDIT', 0)
    commit('SET_IS_FETCHING_REGISTER_CUSTOMER_CREDIT', true)
    return CustomerCreditService.getCustomerCredit({ bookingId, includeTimeline: false })
      .then(({ data }) => {
        if (data.status === 'success' && state.register.bookingId === bookingId) {
          commit('SET_REGISTER_CUSTOMER_CREDIT', data.data.customer_credit)
        }
      })
      .finally(() => {
        commit('SET_IS_FETCHING_REGISTER_CUSTOMER_CREDIT', false)
      })
  },
  getRegisterReceipts ({ state, commit }, bookingId) {
    commit('SET_REGISTER_RECEIPTS', [])
    commit('SET_REGISTER_IS_FETCHING_RECEIPTS', true)
    return BookingService.getBookingReceipts(bookingId)
      .then(({ data }) => {
        if (data.status === 'success' && state.register.bookingId === bookingId) {
          window.enrich.enrichReceipt(data.data.receipts)
          commit('SET_REGISTER_RECEIPTS', data.data.receipts)
        }
      })
      .finally(() => {
        commit('SET_REGISTER_IS_FETCHING_RECEIPTS', false)
      })
  },
  getRegisterInvoices ({ state, commit }, bookingId) {
    commit('SET_REGISTER_INVOICES', [])
    commit('SET_REGISTER_IS_FETCHING_INVOICES', true)
    return BookingService.getBookingInvoices(bookingId)
      .then(({ data }) => {
        if (data.status === 'success' && state.register.bookingId === bookingId) {
          window.enrich.enrichInvoice(data.data.invoices)
          commit('SET_REGISTER_INVOICES', data.data.invoices)
        }
      })
      .finally(() => {
        commit('SET_REGISTER_IS_FETCHING_INVOICES', false)
      })
  },
  getRegisterCharges ({ state, commit }, bookingId) {
    commit('posCart/SET_CHARGES', [], { root: true })
    commit('posCart/SET_IS_LOADING_CART', true, { root: true })
    return BookingService.getBookingCart(bookingId)
      .then(({ data }) => {
        if (data.status === 'success' && state.register.bookingId === bookingId) {
          window.enrich.enrichCharge(data.data.charges)
          commit('posCart/SET_CHARGES', data.data.charges, { root: true })
        }
      })
      .finally(() => {
        commit('posCart/SET_IS_LOADING_CART', false, { root: true })
      })
  },
  getRegisterChargesCalendarItems ({ state, commit }, bookingId) {
    commit('SET_REGISTER_CALENDAR_ITEMS', [])
    commit('SET_REGISTER_IS_FETCHING_CALENDAR_ITEMS', true)
    return BookingService.getAccommodationChargesCalendarItems(bookingId)
      .then(({ data }) => {
        if (data.status === 'success' && state.register.bookingId === bookingId) {
          window.enrich.enrichCalendarItem(data.data.calendar_items)
          commit('SET_REGISTER_CALENDAR_ITEMS', data.data.calendar_items)
        }
      })
      .finally(() => {
        commit('SET_REGISTER_IS_FETCHING_CALENDAR_ITEMS', false)
      })
  },
  parkBooking ({ commit, dispatch }, { charges, booking }) {
    // parkera charges på en booking
    const bookingId = booking.id
    commit('SET_IS_PARKING_BOOKING_IDS', { bookingId, val: true })
    return BookingService.parkBooking({ bookingId, charges })
      .then(({ data }) => {
        if (data.status === 'success') {
          dispatch('posCart/reset', null, { root: true })
          dispatch('booking/openDialogById', { bookingId, startTab: 'other-charges' }, { root: true })
        }
      })
      .finally(() => {
        commit('SET_IS_PARKING_BOOKING_IDS', { bookingId, val: false })
      })
  },
  sendBookingConfirmation ({ state, commit, rootState }, { bookingId }) {
    commit('SET_IS_SENDING_CONFIRMATION_BOOKING_IDS', { bookingId, val: true })
    return BookingService.sendBookingConfirmation({ bookingId })
      .then(({ data }) => {
        // snackbar bekräftelse från servern
        if (data.status === 'success' && (state.dialog.bookingId === bookingId || rootState.customer.dialog.bookingOverlay.bookingId === bookingId)) {
          // bokningen är öppen i dialogen
          commit('SET_IS_AWAITING_EMAIL_SAVED_BOOKING_ID', { bookingId, val: true })
        }
      })
      .finally(() => {
        commit('SET_IS_SENDING_CONFIRMATION_BOOKING_IDS', { bookingId, val: false })
      })
  },
  saveAccommodationCharges ({ state, commit, rootState }, { bookingId, dates, isPeriodAccommodationCharge, price, discountType, discountValue }) {
    commit('SET_IS_SAVING_ACCOMMODATION_CHARGES', true)
    return BookingService.saveAccommodationCharges({ bookingId, dates, isPeriodAccommodationCharge, price, discountType, discountValue })
      .then(({ data }) => {
        if (data.status === 'success') {
          if (data.data.timestamp > state.timestampLastCalendarItems) {
            // Kan skickas flera requests på kort tid, så jämför server timestamp för att säkerställa att det sista svaret från servern är den nya calendar items
            commit('SET_TIMESTAMP_LAST_CALENDAR_ITEMS', data.data.timestamp)
            window.enrich.enrichCalendarItem(data.data.calendar_items)
            if (state.dialog.booking && (state.dialog.booking.id === bookingId)) {
              // Bokningen är fortfarande öppen i dialogen, ersätter calendar items
              commit('SET_DIALOG_BOOKING_CALENDAR_ITEMS', data.data.calendar_items)
            }
            if (state.checkinDialog.booking && (state.checkinDialog.booking.id === bookingId)) {
              // Bokningen är fortfarande öppen i incheckningsdialogen, ersätter calendar items
              commit('SET_CHECKIN_DIALOG_ACCOMMODATION_CHARGES_CALENDAR_ITEMS', data.data.calendar_items)
            }
            if (state.register.booking && (state.register.booking.id === bookingId)) {
              // Bokningen är fortfarande öppen i kassan, ersätter calendar items
              commit('SET_REGISTER_CALENDAR_ITEMS', data.data.calendar_items)
            }
            if (rootState.customer.dialog.bookingOverlay.bookingId === bookingId) {
              // Bokningen är öppen i en booking dialog inuti en customer dialog
              commit('customer/SET_BOOKING_OVERLAY_BOOKING_CALENDAR_ITEMS', data.data.calendar_items, { root: true })
            }
            commit('TRIGGER_ACCOMMODATION_CHARGES_UPDATED') // incrementerar en variabel som bevakas av charges-table så den vet att den ska hämta om platsavgiftssummorna
          }
        }
      })
      .finally(() => {
        commit('SET_IS_SAVING_ACCOMMODATION_CHARGES', false)
      })
  },
  confirmAccommodationCharges ({ state, commit, rootState }, { bookingId, accommodationCharges }) {
    commit('SET_IS_SAVING_ACCOMMODATION_CHARGES', true)
    return BookingService.confirmAccommodationCharges({ bookingId, accommodationCharges })
      .then(({ data }) => {
        if (data.status === 'success') {
          if (data.data.timestamp > state.timestampLastCalendarItems) {
            // Kan skickas flera requests på kort tid, så jämför server timestamp för att säkerställa att det sista svaret från servern är den nya calendar items
            commit('SET_TIMESTAMP_LAST_CALENDAR_ITEMS', data.data.timestamp)
            window.enrich.enrichCalendarItem(data.data.calendar_items)
            if (state.dialog.booking && (state.dialog.booking.id === bookingId)) {
              // Bokningen är fortfarande öppen i dialogen, ersätter calendar items
              commit('SET_DIALOG_BOOKING_CALENDAR_ITEMS', data.data.calendar_items)
            }
            if (state.checkinDialog.booking && (state.checkinDialog.booking.id === bookingId)) {
              // Bokningen är fortfarande öppen i incheckningsdialogen, ersätter calendar items
              commit('SET_CHECKIN_DIALOG_ACCOMMODATION_CHARGES_CALENDAR_ITEMS', data.data.calendar_items)
            }
            if (state.register.booking && (state.register.booking.id === bookingId)) {
              // Bokningen är fortfarande öppen i kassan, ersätter calendar items
              commit('SET_REGISTER_CALENDAR_ITEMS', data.data.calendar_items)
            }
            if (rootState.customer.dialog.bookingOverlay.bookingId === bookingId) {
              // Bokningen är öppen i en booking dialog inuti en customer dialog
              commit('customer/SET_BOOKING_OVERLAY_BOOKING_CALENDAR_ITEMS', data.data.calendar_items, { root: true })
            }
            commit('TRIGGER_ACCOMMODATION_CHARGES_UPDATED') // incrementerar en variabel som bevakas av charges-table så den vet att den ska hämta om platsavgiftssummorna
          }
        }
      })
      .finally(() => {
        commit('SET_IS_SAVING_ACCOMMODATION_CHARGES', false)
      })
  },
  dismissAccommodationCharges ({ state, commit, rootState }, { bookingId, accommodationCharges }) {
    commit('SET_IS_SAVING_ACCOMMODATION_CHARGES', true)
    return BookingService.dismissAccommodationCharges({ bookingId, accommodationCharges })
      .then(({ data }) => {
        if (data.status === 'success') {
          if (data.data.timestamp > state.timestampLastCalendarItems) {
            // Kan skickas flera requests på kort tid, så jämför server timestamp för att säkerställa att det sista svaret från servern är den nya calendar items
            commit('SET_TIMESTAMP_LAST_CALENDAR_ITEMS', data.data.timestamp)
            window.enrich.enrichCalendarItem(data.data.calendar_items)
            if (state.dialog.booking && (state.dialog.booking.id === bookingId)) {
              // Bokningen är fortfarande öppen i dialogen, ersätter calendar items
              commit('SET_DIALOG_BOOKING_CALENDAR_ITEMS', data.data.calendar_items)
            }
            if (state.checkinDialog.booking && (state.checkinDialog.booking.id === bookingId)) {
              // Bokningen är fortfarande öppen i incheckningsdialogen, ersätter calendar items
              commit('SET_CHECKIN_DIALOG_ACCOMMODATION_CHARGES_CALENDAR_ITEMS', data.data.calendar_items)
            }
            if (state.register.booking && (state.register.booking.id === bookingId)) {
              // Bokningen är fortfarande öppen i kassan, ersätter calendar items
              commit('SET_REGISTER_CALENDAR_ITEMS', data.data.calendar_items)
            }
            if (rootState.customer.dialog.bookingOverlay.bookingId === bookingId) {
              // Bokningen är öppen i en booking dialog inuti en customer dialog
              commit('customer/SET_BOOKING_OVERLAY_BOOKING_CALENDAR_ITEMS', data.data.calendar_items, { root: true })
            }
          }
        }
      })
      .finally(() => {
        commit('SET_IS_SAVING_ACCOMMODATION_CHARGES', false)
      })
  }

}

export const getters = {
  checkinList: function (state) {
    if (state.bookings.length === 0) {
      return []
    }
    const bookings = state.bookings.filter(booking => {
      // Ska checka in idag eller tidigare (och inte gjort det än), eller har checkat in idag
      return (!booking.is_draft && !!booking.confirmed_at && booking.check_in.isSameOrBefore(window.dayjs()) && !booking.checked_in_at) || (!!booking.checked_in_at && booking.checked_in_at.isToday())
    })
    bookings.sort((a, b) => (a.customer.full_name < b.customer.full_name ? -1 : 1)) // Sorterar i bokstavsordning på kundnamn
    return bookings
  },
  checkinListTomorrow: function (state) {
    if (state.bookings.length === 0) {
      return []
    }
    const bookings = state.bookings.filter(booking => {
      // Incheckningsdag imorgon och inte redan incheckad
      const tomorrow = window.dayjs().startOf('day').add(1, 'day')
      return (!booking.is_draft && !!booking.confirmed_at && booking.check_in.isSame(tomorrow) && !booking.checked_in_at)
    })
    bookings.sort((a, b) => (a.customer.full_name < b.customer.full_name ? -1 : 1)) // Sorterar i bokstavsordning på kundnamn
    return bookings
  },
  checkoutList: function (state) {
    if (state.bookings.length === 0) {
      return []
    }
    const bookings = state.bookings.filter(booking => {
      // Ska checka in idag eller tidigare (och inte gjort det än), eller har checkat in idag
      return (!booking.is_draft && !!booking.confirmed_at && !!booking.checked_in_at && !booking.checked_out_at && booking.check_out.isSameOrBefore(window.dayjs())) || (!!booking.checked_out_at && booking.checked_out_at.isToday())
    })
    bookings.sort((a, b) => (a.customer.full_name < b.customer.full_name ? -1 : 1)) // Sorterar i bokstavsordning på kundnamn
    return bookings
  },
  checkoutListTomorrow: function (state) {
    if (state.bookings.length === 0) {
      return []
    }
    const bookings = state.bookings.filter(booking => {
      // Utcheckningsdag imorgon och inte redan utcheckad (kan vara ej incheckad)
      const tomorrow = window.dayjs().startOf('day').add(1, 'day')
      return (!booking.is_draft && !!booking.confirmed_at && booking.check_out.isSame(tomorrow) && !booking.checked_out_at)
    })
    bookings.sort((a, b) => (a.customer.full_name < b.customer.full_name ? -1 : 1)) // Sorterar i bokstavsordning på kundnamn
    return bookings
  },
  positionIdsWithCollision: function (state, getters) {
    return getters.checkinList.filter(a => !a.checked_in_at && getters.checkoutList.some(b => !b.checked_out_at && a.position_id === b.position_id)).map(booking => booking.position_id)
  },
  recentCheckinsCheckouts: function (state) {
    if (state.bookings.length === 0) {
      return []
    }
    const yesterday = window.dayjs().subtract(1, 'day').startOf('day')
    const bookings = state.bookings.filter(booking => {
      // Checkat in eller checkat ut idag eller igår
      return (!booking.is_draft && !!booking.checked_in_at && booking.checked_in_at.isSameOrAfter(yesterday)) || (!!booking.checked_out_at && booking.checked_out_at.isSameOrAfter(yesterday))
    })
    bookings.sort((a, b) => ((a.checked_out_at || a.checked_in_at).isAfter(b.checked_out_at || b.checked_in_at) ? -1 : 1)) // Sorterar på in-/utcheckningstid
    return bookings
  },
  recentBookings: function (state) {
    if (state.bookings.length === 0) {
      return []
    }
    const yesterday = window.dayjs().subtract(1, 'day').startOf('day')
    const bookings = state.bookings.filter(booking => {
      // Bokning bekräftad idag eller igår
      return (!booking.is_draft && !!booking.confirmed_at && booking.confirmed_at.isSameOrAfter(yesterday))
    })
    bookings.sort((a, b) => (a.confirmed_at.isAfter(b.confirmed_at) ? -1 : 1)) // Sorterar på confirmed_at
    return bookings
  },
  checkedInBookings: function (state) {
    if (state.bookings.length === 0) {
      return []
    }
    const bookings = state.bookings.filter(booking => {
      return (!booking.is_draft && booking.checked_in_at !== null && booking.checked_out_at === null)
    })
    return bookings
  },
  bookingDrafts: function (state) {
    if (state.bookings.length === 0) {
      return []
    }
    const now = window.dayjs() // OBS: denna metod är inte reaktiv på "now" (rootState.now gjorde den inte reaktiv heller, vet inte varför), men eftersom bookings uppdateras ganska regelbundet tycker jag inte den behöver beräknas om oftare än så
    const bookings = state.bookings.filter(booking => {
      return booking.is_draft && booking.draft_expires_at.isAfter(now)
    })
    bookings.sort((a, b) => {
      // b före a = 1, a före b = -1
      return a.draft_expires_at.isBefore(b.draft_expires_at) ? 1 : -1
    })
    return bookings
  }
}
