import OrderService from '@/services/OrderService.js'

export const namespaced = true

export const state = {
  orders: [],
  isFetchingOrders: false,
  isCreatingOrder: false,
  isParkingOrderIds: [],
  isDeletingOrderIds: []
}

export const mutations = {
  SET_IS_FETCHING_ORDERS (state, val) {
    state.isFetchingOrders = val
  },
  SET_IS_CREATING_ORDER (state, val) {
    state.isCreatingOrder = val
  },
  SET_IS_PARKING_ORDER_IDS (state, payload) {
    if (payload.val) {
      const i = state.isParkingOrderIds.findIndex(id => id === payload.orderId)
      if (i < 0) {
        state.isParkingOrderIds.push(payload.orderId)
      }
    } else {
      const i = state.isParkingOrderIds.findIndex(id => id === payload.orderId)
      state.isParkingOrderIds.splice(i, 1)
    }
  },
  SET_IS_DELETING_ORDER_IDS (state, payload) {
    if (payload.val) {
      const i = state.isDeletingOrderIds.findIndex(id => id === payload.orderId)
      if (i < 0) {
        state.isDeletingOrderIds.push(payload.orderId)
      }
    } else {
      const i = state.isDeletingOrderIds.findIndex(id => id === payload.orderId)
      state.isDeletingOrderIds.splice(i, 1)
    }
  },
  SET_ORDERS (state, val) {
    state.orders = val
  },
  REPLACE_OR_INSERT_ORDER (state, order) {
    const i = state.orders.findIndex(o => o.id === order.id)
    if (i >= 0) {
      state.orders.splice(i, 1, order)
    } else {
      state.orders.push(order)
    }
  },
  DELETE_ORDER (state, orderId) {
    const i = state.orders.findIndex(o => o.id === orderId)
    if (i >= 0) {
      state.orders.splice(i, 1)
    }
  }
}

export const actions = {
  fetchOrders ({ commit, dispatch, rootState }) {
    commit('SET_IS_FETCHING_ORDERS', true)
    return OrderService.getOrders()
      .then(({ data }) => {
        commit('SET_ORDERS', data.data.parked_orders)
      })
      .finally(() => {
        commit('SET_IS_FETCHING_ORDERS', false)
        window.channel.listen('ParkedOrdersSaved', function (data) {
          const workstationId = data.workstation_id
          if (workstationId !== rootState.workstation?.id) {
            // hämtar inte om parkerad nota ifall den skapats/redigerats på den här enheten
            const parkedOrderIds = data.parked_order_ids
            parkedOrderIds.forEach(id => {
              dispatch('fetchOrder', id)
            })
          }
        })
        window.channel.listen('ParkedOrderRemoved', function (data) {
          commit('DELETE_ORDER', data.parked_order_id)
        })
        window.channel.listen('PrintBong', function (data) {
          const printerId = data.printer_id
          const print = data.print_object
          dispatch('print/printBong', { printerId, print }, { root: true })
        })
      })
  },
  fetchOrder ({ commit }, parkedOrderId) {
    return OrderService.getOrder({ parkedOrderId })
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichOrder(data.data.parked_order)
          commit('REPLACE_OR_INSERT_ORDER', data.data.parked_order)
        }
      })
      .finally(() => {
        //
      })
  },
  enrichOrders ({ state }) {
    return new Promise((resolve, reject) => {
      window.enrich.enrichOrder(state.orders)
      resolve(true)
    })
  },
  openOrderById ({ state, dispatch }, orderId) {
    const order = state.orders.find(o => o.id === orderId)
    if (!order) {
      // kan vara att ordrar håller på att hämtas? (alla orders borde vara hämtade och berikade när denna metod körs)
      dispatch('snackbars/createSnackbar', {
        color: 'error',
        text: 'Den parkerade notan hittades inte'
      }, { root: true })
      return false
    }
    dispatch('posCart/openOrder', order, { root: true })
  },
  createNewOrder ({ state, commit, rootState, dispatch }, name) {
    // spara ny order med nuvarande varukorg

    const orderWithSameName = state.orders.find(order => order.name === name)
    if (orderWithSameName) {
      // Det finns redan en order med valt namn, frågar om ska lägga till varukorgen på den ordern istället
      const newCart = orderWithSameName.charges.concat(rootState.posCart.charges)
      commit('SET_OPEN_CONFIRMATION_DIALOG', {
        header: 'Nota finns redan.',
        body: 'Det finns redan en parkerad nota med samma namn, fortsätt för att spara varor på samma nota.',
        confirmationFunction: 'posOrder/parkOrder',
        confirmationArgument: { charges: newCart, order: orderWithSameName }
      }, { root: true })
      return false
    }

    commit('SET_IS_CREATING_ORDER', true)
    return OrderService.createOrder({ name, charges: rootState.posCart.charges })
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichOrder(data.data.parked_order)
          commit('REPLACE_OR_INSERT_ORDER', data.data.parked_order)
          dispatch('posCart/reset', null, { root: true })
        }
      })
      .finally(() => {
        commit('SET_IS_CREATING_ORDER', false)
      })
  },
  parkOrder ({ commit, dispatch }, { charges, order, shouldResetCart = true }) {
    // parkera existerande order
    commit('SET_IS_PARKING_ORDER_IDS', { orderId: order.id, val: true })
    return OrderService.parkOrder({ orderId: order.id, charges })
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichOrder(data.data.parked_order)
          commit('REPLACE_OR_INSERT_ORDER', data.data.parked_order)
          if (shouldResetCart) {
            dispatch('posCart/reset', null, { root: true })
          }
        }
      })
      .finally(() => {
        commit('SET_IS_PARKING_ORDER_IDS', { orderId: order.id, val: false })
      })
  },
  mergeOrders ({ dispatch }, { orders }) {
    const firstOrder = orders[0]
    const ordersToBeDeleted = orders.slice(1)

    // Slår ihop varukorgen på alla orders
    let mergedCart = []
    orders.forEach(order => {
      mergedCart = mergedCart.concat(order.charges)
    })

    // Sparar hela varukorgen på första ordern
    dispatch('parkOrder', { charges: mergedCart, order: firstOrder, shouldResetCart: false })
      .then(() => {
        // Tar bort resterande ordrar
        ordersToBeDeleted.forEach(order => {
          dispatch('deleteOrder', order)
        })
      })
  },
  deleteOrder ({ commit }, order) {
    // ta bort existerande order - och tillhörande charges
    commit('SET_IS_DELETING_ORDER_IDS', { orderId: order.id, val: true })
    return OrderService.deleteOrder({ orderId: order.id })
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('DELETE_ORDER', order.id)
        } else if (data.status === 'error' && data.error_code === 'parked_order_already_deleted') {
          commit('DELETE_ORDER', order.id)
        }
      })
      .finally(() => {
        commit('SET_IS_DELETING_ORDER_IDS', { orderId: order.id, val: false })
      })
  },
  updateOrderName ({ commit }, { order, newName }) {
    // Kontrollerar om redan finns en parkerad nota med det nya namnet
    const orderWithSameName = state.orders.find(existingOrder => existingOrder.name === newName)
    if (orderWithSameName) {
      // Det finns redan en order med valt namn, frågar om ska merga de två
      commit('SET_OPEN_CONFIRMATION_DIALOG', {
        header: 'Nota med det namnet finns redan.',
        body: 'Det finns redan en parkerad nota med samma namn, vill du slå ihop varukorgen på de båda notorna?',
        confirmationFunction: 'posOrder/mergeOrders',
        confirmationArgument: { orders: [orderWithSameName, order] } // eftersom orderWithSameName är först i arrayen så är det den som behålls, och den har redan rätt namn
      }, { root: true })
      return false
    }

    const orderId = order.id
    return OrderService.updateOrderName({ orderId, newName })
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichOrder(data.data.parked_order)
          commit('REPLACE_OR_INSERT_ORDER', data.data.parked_order)
        }
      })
      .finally(() => {
        //
      })
  }
}

export const getters = {

}
