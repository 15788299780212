<template>
  <v-text-field
    label="Email"
    v-model="email"
    autocomplete="chrome-off"
    clearable
    :error-messages="emailMessages"
    :loading="validation.isLoading"
    @click:clear="resetValidation"
    :class="{
      'sub-errors-for-warning warning--text': validation.hasWarning,
      'input-success success--text': hasSuccess
    }"
  ></v-text-field>
</template>

<script>
import ValidationService from '@/services/ValidationService.js'

export default {
  name: 'CustomerInputEmail',
  props: {
    forceValidation: Number,
    forceResetValidation: Number,
    value: String,
    customerId: Number
  },
  data: () => ({
    validation: {
      hasChanges: false,
      isLoading: false,
      isValidated: false,
      hasError: false,
      hasWarning: false,
      errorMessage: '',
      suggestions: []
    },
    originalEmail: ''
  }),
  computed: {
    email: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    emailMessages: function () {
      if (this.validation.errorMessage) {
        return [this.validation.errorMessage]
      } else {
        return []
      }
    },
    hasSuccess: function () {
      return this.email !== '' && this.email !== null && this.validation.isValidated && !this.validation.hasWarning && !this.validation.hasError && !this.validation.isLoading
    }
  },
  watch: {
    email: function () {
      this.validateEmail()
    },
    forceValidation: function () {
      this.validateEmail(true)
    },
    forceResetValidation: function () {
      this.resetValidation()
    }
  },
  methods: {
    regexValidation: function (email) {
      const re = /[a-zåäö0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zåäö0-9](?:[a-zåäö0-9-]*[a-zåäö0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?/g
      return re.test(String(email).toLowerCase())
    },
    resetValidation: function () {
      this.validation.hasChanges = false
      this.validation.isLoading = false
      this.validation.isValidated = false
      this.validation.hasError = false
      this.validation.hasWarning = false
      this.validation.errorMessage = ''
      this.validation.suggestions = []
    },
    validateEmail: function () {
      const email = this.email
      this.resetValidation()

      if (email === '' || email === null) {
        this.validation.isValidated = true
      } else if (!this.regexValidation(email)) {
        this.validation.errorMessage = 'E-postadressen är felformatterad'
        this.validation.isValidated = true
        this.validation.hasError = true
      } else {
        this.validation.isLoading = true
        ValidationService.validateEmail(email)
          .then(({ data }) => {
            data = data.data.validation_result
            if (this.email === email) {
              if (data.status === 'verify') {
                this.validation.hasWarning = true
                this.validation.errorMessage = 'E-postadressen kunde inte bekräftas giltig'
              } else if (data.status === 'invalid') {
                this.validation.hasError = true
                this.validation.errorMessage = 'E-postadressen finns inte'
              } else if (data.is_customer && this.customerId !== data.customer.id) {
                this.validation.hasError = true
                this.validation.suggestions.push({
                  customer: data.customer,
                  reason: 'Samma e-postadress'
                })
                this.validation.errorMessage = 'E-postadressen används av: ' + data.customer.full_name
              }
            }
          })
          .finally(() => {
            if (this.email === email) {
              this.validation.isValidated = true
              this.validation.isLoading = false
              this.validation.hasChanges = (this.originalEmail === email)
              this.$emit('set-validation', this.validation)
            }
          })
      }
      this.$emit('set-validation', this.validation)
    }
  }
}
</script>
