import Vue from 'vue'
import TallyService from '@/services/TallyService.js'
export const namespaced = true

export const state = {
  isCreatingProvisionBookingIds: [],
  isAddingCardBookingIds: [], // kan innehålla samma booking id flera gånger, om man lägger till flera kort på samma booking innan tidigare requests avslutats
  isRemovingCardUids: [],
  timestampLastTallyResource: 0,
  recentlyScannedUids: [],
  activeTallyCards: []
}

export const mutations = {
  SET_IS_CREATING_PROVISION_BOOKING_IDS (state, payload) {
    if (payload.val) {
      const i = state.isCreatingProvisionBookingIds.findIndex(id => id === payload.bookingId)
      if (i < 0) {
        state.isCreatingProvisionBookingIds.push(payload.bookingId)
      }
    } else {
      const i = state.isCreatingProvisionBookingIds.findIndex(id => id === payload.bookingId)
      state.isCreatingProvisionBookingIds.splice(i, 1)
    }
  },
  SET_IS_ADDING_CARD_BOOKING_IDS (state, payload) {
    if (payload.val) {
      state.isAddingCardBookingIds.push(payload.bookingId)
    } else {
      const i = state.isAddingCardBookingIds.findIndex(id => id === payload.bookingId)
      state.isAddingCardBookingIds.splice(i, 1)
    }
  },
  SET_IS_REMOVING_CARD_UIDS (state, payload) {
    if (payload.val) {
      const i = state.isRemovingCardUids.findIndex(id => id === payload.cardUid)
      if (i < 0) {
        state.isRemovingCardUids.push(payload.cardUid)
      }
    } else {
      const i = state.isRemovingCardUids.findIndex(id => id === payload.cardUid)
      state.isRemovingCardUids.splice(i, 1)
    }
  },
  SET_TIMESTAMP_LAST_TALLY_RESOURCE (state, val) {
    state.timestampLastTallyResource = val
  },
  ADD_RECENTLY_SCANNED_UID (state, val) {
    state.recentlyScannedUids.push(val)
    setTimeout(() => {
      const i = state.recentlyScannedUids.findIndex(uid => uid === val)
      if (i >= 0) {
        state.recentlyScannedUids.splice(i, 1)
      }
    }, 4000)
  },
  SET_ACTIVE_CARDS (state, val) {
    state.activeTallyCards = val
  },
  ADD_CARD (state, val) {
    state.activeTallyCards.push({ card_uid: val.card_uid, booking_id: val.booking_id })
  },
  REMOVE_CARD (state, val) {
    state.activeTallyCards = state.activeTallyCards.filter(card => card.card_uid !== val)
  },
  REMOVE_BOOKING (state, val) {
    state.activeTallyCards = state.activeTallyCards.filter(card => card.booking_id !== val)
  }
}

export const actions = {
  createProvision ({ commit }, booking) {
    const bookingId = booking.id
    commit('SET_IS_CREATING_PROVISION_BOOKING_IDS', { bookingId, val: true })
    return TallyService.createProvision({ bookingId })
      .then(({ data }) => {
        if (data.status === 'success') {
          Vue.set(booking, 'tally_resource', data.data.tally_resource)
        }
      })
      .finally(() => {
        commit('SET_IS_CREATING_PROVISION_BOOKING_IDS', { bookingId, val: false })
      })
  },
  addCard ({ state, commit }, { input, param }) {
    const booking = param
    const bookingId = booking.id
    const cardUid = parseInt(input, 16)
    if (isNaN(cardUid)) {
      // Behöver nog inte felmeddelande? är sannolikt misstag
      return false
    }
    commit('ADD_RECENTLY_SCANNED_UID', cardUid)
    if (booking.tally_resource) {
      const existingCard = booking.tally_resource.cards.find(c => c.card_uid === cardUid)
      if (existingCard) {
        // Lägger inte till kortet igen om det redan finns i tally_resource
        return false
      }
    }

    commit('SET_IS_ADDING_CARD_BOOKING_IDS', { bookingId, val: true })
    return TallyService.addCard({ bookingId, cardUid })
      .then(({ data }) => {
        if (data.status === 'success') {
          if (data.data.timestamp > state.timestampLastTallyResource) {
            commit('SET_TIMESTAMP_LAST_TALLY_RESOURCE', data.data.timestamp)
            Vue.set(booking, 'tally_resource', data.data.tally_resource)
          }
        }
      })
      .finally(() => {
        commit('SET_IS_ADDING_CARD_BOOKING_IDS', { bookingId, val: false })
      })
  },
  removeCard ({ state, commit }, { cardUid, booking }) {
    const bookingId = booking.id
    commit('SET_IS_REMOVING_CARD_UIDS', { cardUid, val: true })
    return TallyService.removeCard({ bookingId, cardUid })
      .then(({ data }) => {
        if (data.status === 'success') {
          if (data.data.timestamp > state.timestampLastTallyResource) {
            commit('SET_TIMESTAMP_LAST_TALLY_RESOURCE', data.data.timestamp)
            Vue.set(booking, 'tally_resource', data.data.tally_resource)
          }
        }
      })
      .finally(() => {
        commit('SET_IS_REMOVING_CARD_UIDS', { cardUid, val: false })
      })
  },
  regeneratePincode ({ commit }, booking) {
    return TallyService.regeneratePincode(booking.id)
      .then(({ data }) => {
        if (data.status === 'success') {
          if (data.data.timestamp > state.timestampLastTallyResource) {
            commit('SET_TIMESTAMP_LAST_TALLY_RESOURCE', data.data.timestamp)
            Vue.set(booking, 'tally_resource', data.data.tally_resource)
          }
        }
      })
  },
  fetchCards ({ commit }) {
    commit('SET_ACTIVE_CARDS', [])
    TallyService.fetchCards()
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_ACTIVE_CARDS', data.data.cards)
        }
      })
      .finally(() => {
        window.channel.listen('AddedTallyCard', function (data) {
          commit('ADD_CARD', { card_uid: data.card_uid, booking_id: data.booking_id })
        })
        window.channel.listen('RemovedTallyCard', function (data) {
          commit('REMOVE_CARD', data.card_uid)
        })
      })
  }
}
