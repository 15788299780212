import API from './API.js'

export default {
  getReceipts ({ requestId, offset, visibleReceipts, sortedColumn, sortedDesc, filterRegisters, filterCreatedAt, searchString }) {
    return API.httpClient.get('receipts', {
      params: {
        request_id: requestId,
        offset,
        visible_receipts: visibleReceipts,
        sorted_column: sortedColumn,
        sorted_desc: sortedDesc,
        filter_registers: filterRegisters,
        filter_created_at: filterCreatedAt,
        search_string: searchString
      }
    })
  },
  getReceipt (id) {
    return API.httpClient.get(`receipts/${id}`)
  }
}
