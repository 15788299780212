import router from '@/router'
import CheckoutService from '@/services/CheckoutService.js'
import PaymentMethodService from '@/services/PaymentMethodService.js'

export const namespaced = true

export const state = {
  paymentMethods: [],
  isFetchingPaymentMethods: false,
  preparedReceipt: null,
  isLoadingPayment: false,
  isLoadingTerminalPayment: false,
  checkOutOnCompletedSale: false,
  triggerRetryPayment: 0
}

export const mutations = {
  SET_PAYMENT_METHODS (state, val) {
    state.paymentMethods = val
  },
  SET_IS_FETCHING_PAYMENT_METHODS (state, val) {
    state.isFetchingPaymentMethods = val
  },
  SET_IS_LOADING_PAYMENT (state, val) {
    state.isLoadingPayment = val
  },
  SET_IS_LOADING_TERMINAL_PAYMENT (state, val) {
    state.isLoadingTerminalPayment = val
  },
  SET_PREPARED_RECEIPT (state, val) {
    state.preparedReceipt = val
  },
  SET_CONTROL_UNIT_RESPONSE (state, val) {
    state.preparedReceipt.control_unit_response = val
  },
  TRIGGER_RETRY_PAYMENT (state, val) {
    state.triggerRetryPayment++
  },
  SET_CHECK_OUT_ON_COMPLETED_SALE (state, val) {
    state.checkOutOnCompletedSale = val
  }
}

export const actions = {
  fetchPaymentMethods ({ commit }) {
    commit('SET_IS_FETCHING_PAYMENT_METHODS', true)
    return PaymentMethodService.fetchPaymentMethods()
      .then(({ data }) => {
        commit('SET_PAYMENT_METHODS', data.data.payment_methods)
        commit('SET_IS_FETCHING_PAYMENT_METHODS', false)
      })
  },
  initPayment ({ rootState, dispatch, commit }, { paymentMethod, amount, booking, parkedOrder, charges, accommodationChargesAmount = 0, customerCreditAmount = 0, shouldCheckOut = false }) {
    commit('posTerminal/SET_TERMINAL_CANCELLING', false, { root: true })
    commit('SET_IS_LOADING_PAYMENT', true)
    commit('SET_CHECK_OUT_ON_COMPLETED_SALE', shouldCheckOut)
    let paymentMethodId
    if (paymentMethod === null) {
      paymentMethodId = null
    } else {
      paymentMethodId = paymentMethod.id
      if (paymentMethod.gateway === 'integrated_terminal') {
        commit('SET_IS_LOADING_TERMINAL_PAYMENT', true)
      }
    }
    dispatch('posErrors/checkCleanCash', undefined, { root: true })
      .then(() => {
        if (rootState.posErrors.hasFatalError) {
          console.error('HAS FATAL ERROR - CAN NOT PROCESS PAYMENT')
        } else {
          dispatch('prepareSales', { paymentMethodId, charges, amount, accommodationChargesAmount, customerCreditAmount, booking, parkedOrder, shouldCheckOut })
        }
      })
      .catch((error) => {
        console.log('checkCleanCash error', error)
        commit('SET_IS_LOADING_PAYMENT', false)
        commit('SET_IS_LOADING_TERMINAL_PAYMENT', false)
      })
  },
  prepareSales ({ rootState, commit, dispatch }, { paymentMethodId, charges, amount, accommodationChargesAmount, customerCreditAmount, booking, parkedOrder, shouldCheckOut }) {
    const bookingId = booking ? booking.id : null
    const parkedOrderId = parkedOrder ? parkedOrder.id : null
    CheckoutService.prepareSales({ registerId: rootState.posRegister.register.id, paymentMethodId, amount, accommodationChargesAmount, customerCreditAmount, charges, bookingId, parkedOrderId, shouldCheckOut })
      .then(({ data }) => {
        if (data.status === 'success') {
          const requiresSignature = rootState.posRegister.register.current_control_unit.requires_client_signature
          if (!requiresSignature) {
            dispatch('completeSales', { isCopy: false, printObject: data.data.print })
              .then(() => {
                if (bookingId !== null) {
                  dispatch('booking/openDialogById', { bookingId, startTab: 'receipts' }, { root: true })
                }
              })
            return
          }

          dispatch('sendReceiptToControlUnit', data.data.prepared_receipt)
            .then(() => {
              dispatch('completeSales', { isCopy: false })
                .then(() => {
                  if (bookingId !== null) {
                    dispatch('booking/openDialogById', { bookingId, startTab: 'receipts' }, { root: true })
                  }
                })
            })
        } else {
          commit('SET_IS_LOADING_PAYMENT', false)
          if (data.error_code === 'nets_failure') {
            if (data.error_message === 'Terminal / cancelled') { // När kassören avbryter behövs inte återkoppling på samma sätt
              commit('posTerminal/SET_TERMINAL_ERROR', null, { root: true })
              commit('posTerminal/SET_TERMINAL_MESSAGE', null, { root: true })
            } else {
              commit('posTerminal/SET_TERMINAL_ERROR', data.data.print, { root: true })
              commit('posTerminal/SET_TERMINAL_MESSAGE', data.data.failure_message, { root: true })
            }
            commit('posTerminal/SET_TERMINAL_CANCELLING', false, { root: true })
          }
        }
      })
      .catch((error) => {
        console.log('prepareSales error', error)
        commit('SET_IS_LOADING_PAYMENT', false)
      })
      .finally(() => {
        commit('posTerminal/SET_TERMINAL_ACTIVE', false, { root: true })
        commit('posTerminal/SET_TERMINAL_CANCELLING', false, { root: true })
        commit('SET_IS_LOADING_TERMINAL_PAYMENT', false)
      })
  },
  async sendReceiptToControlUnit ({ commit }, preparedReceipt) {
    commit('SET_PREPARED_RECEIPT', preparedReceipt)
    const response = await window.cleancash.registerReceipt(preparedReceipt.control_object)
    commit('SET_CONTROL_UNIT_RESPONSE', response)
  },
  completeSales ({ rootState, state, commit, dispatch }, { isCopy, printObject }) {
    const shouldCheckOut = state.checkOutOnCompletedSale
    const requiresSignature = rootState.posRegister.register.current_control_unit.requires_client_signature

    const printAndNavigate = (receiptPrint) => {
      dispatch('posReceipt/printReceipt', {
        printObject: receiptPrint,
        shouldResetCart: !isCopy && !shouldCheckOut,
        shouldCancelCart: !isCopy && shouldCheckOut
      }, { root: true })
        .finally(() => {
          commit('SET_IS_LOADING_PAYMENT', false)
        })

      if (shouldCheckOut) {
        router.push({ name: 'ActionsItems' })
      }
    }

    if (!requiresSignature) {
      printAndNavigate(printObject)
      return
    }

    const receiptId = state.preparedReceipt.receipt_id
    const controlData = state.preparedReceipt.control_unit_response
    const bongProductsCount = state.preparedReceipt.bong_products_count

    return CheckoutService.completeSales({ receiptId, controlData, shouldCheckOut, bongProductsCount })
      .then(({ data }) => {
        if (data.status === 'success') {
          printAndNavigate(data.data.print)
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_PAYMENT', false)
      })
  }
}
